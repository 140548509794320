import internal from "assert";

export interface ShippingAddressOptions {
  title: string;
  billingAddress: string;
  projectAddress: string;
  leasingOfficeAddress: string;
  maintenanceOfficeAddress: string;
  newAddress: string;
  toContinue: string;
  invalidPhone: string;
  cpuEmail: string;
  selectedOption?: boolean;
  customerPickUp: string;
  pOBox: string;
}
export class ShippingAddressForm {
  constructor(
    public shippingAddressName: string,
    public shippingAddressLine1: string,
    public shippingAddressLine2: string,
    public shippingAddressLine3: string,
    public shippingAddressZipCode: string | number,
    public shippingAddressCity: string,
    public shippingAddressPhone: number | string,
    public shippingAddressNotes: string,
    public shippingAddressState?: string,
    public shippingAddressStateID?: number,
    public entranceCode?: string
    ) {}
}

export class ShippingOptions {
  constructor(
    public customerBillingAddress: boolean,
    public apartmentProjectAddress: boolean,
    public leasingOfficeAddress: boolean,
    public maintenanceOfficeAddress: boolean,
    public newShippingAddress: boolean,
    public customerPickUp: boolean
  ) {}
}

export class NewAddress {
  constructor(
    public id: number,
    public city: string,
    public StateProvinceID: number,
    public addressLine1: string,
    public addressLine2: string,
    public addressLine3: string,
    public postalCode: string,
    public addressName: string,
    public addressPhone: string,
    public addressNotes: string,
    public complexID: number,
    public isQuote: string,
    public apartmentID: number
  ) {}
}

export class PalletizeContactForm {
  constructor(
    public shippingAddressName: string,
    public shippingAddressLine1: string,
    public shippingAddressLine2: string,
    public shippingAddressZipCode: string,
    public shippingAddressCity: string,
    public shippingAddressState: string,
    public shippingAddressStateId: number,
    public shippingAddressPhone: string,
    public shippingInstructions: string,
    public fullName: string,
    public phoneNumber: string,
    public emailAddress: string,
    public entranceCode: string,
    public aditionalInformation: string,
    public isInsideDeliveryRequired: boolean,
    public isLimitedAccessToAddress: boolean,
    public isLiftgateRequired: boolean,
    public runPackagingPlanning: boolean,
    public palletizeOptionID: number,
    public deliveryDate: string,
    public canDelieverEarly: boolean,
    public palletFormId: number,
    public contactList:  Array<PalletizeContact>
    ) {}
}

export class PalletizeContact {
  constructor(
    public id: number,
    public fullName: string,
    public phoneNumber: string,
    public emailAddres: string,    
    public contactTypeId: number,
    ) {}
}
