import {
  Account,
  ErrorTxt,
  AccountPlaceholder,
  UserPlaceholder,
  AccountTxt,
  UserCreditTxt,
  ResetPassPlaceholder,
  InputError } from '../types/create-account';

export const accountPlaceholder: AccountPlaceholder = {
  company_name: 'Company Name',
  contact_first_name: 'First Name',
  contact_last_name: 'Last Name',
  phone: 'Phone',
  email: 'Login email',
  confirm_email: 'Confirm Login email',
  password: 'Password',
  confirm_password: 'Confirm Password',
  full_name: 'Full Name*',
  address_line_1: 'Address Line 1',
  address_line_2: 'Address Line 2',
  address_line_3: 'Address Line 3',
  city: 'City',
  zipcode: 'Zip Code',
  phone_number: 'Office Phone Number',
  invoicing_email: 'Invoicing Email address(es)',
  fedex_account: 'Fedex account number',
  role: 'Select a Customer Role',
  state: 'State',
  salesperson: 'Select Salesperson Code',
  customer_pricing_group: 'Select Customer Pricing Group',
  service_zone: 'Select Delivery Pricing Group',
  tax_status: 'Select Sales Tax Status',
  about_us: 'Select Lead Source',
  apt_renovate: '# Apartments renovated per year',
  special_instructions: 'Invoicing Instructions',
  special_reference: 'Invoicing Reference Number',
  account_id: 'Salesforce Account ID',
};

export const userPlaceholder: UserPlaceholder = {
  email: 'Email',
  customerNumber: 'Customer Number',
  password: 'Password',
};

export const errorTxt: ErrorTxt = {
  email_not_equal: `*The emails don't match`,
  invalid_apt_number: '*Invalid apartments number',
  invalid_credit_limit: '*Invalid credit limit',
  invalid_customerName:'*Maximum length of the customer name should be 50',
  invalid_email: '*This email is already taken or is not a valid emai.',
  invalid_password: '*Password too weak. (Min: 8 characters, lowercase, uppercase, numbers and special characters)',
  invalid_phone: '*Invalid phone.',
  invalid_salesForceAccount: '*This field is required and must start with "SFA-" and follow by 6 numbers',
  invalid_zipcode: '*Invalid Zip Code',
  password_not_equal: `*The passwords doesn't match`,
  required: '*This field is required.',
  user_exist: '*This email is already associated with an existing account, please login to that account.',
  location_exist: '*The name of Community already exists.',
  is_po_box: '*PO Boxes are not allowed',
};

export const accountTxt: AccountTxt = {
  account_creation: 'Account Creation',
  customer_details: 'Customer Details',
  company_name: 'Company Name',
  login_information: 'Login Information',
  office_info: 'Office Contact Information',
  // tslint:disable-next-line:max-line-length
  invoicing_emails: 'Please enter email address for invoicing. You may enter multiple emails, separated by semicolons, up to a total of 80 characters.',
  user_role: 'Please select a role that best describes the company.',
  apt_renovate: 'How many apartments does the customer renovate each year.',
  about_us: 'How did the customer hear about Qwikkit?',
  spam: 'Yes, I would like to receive Qwikkit newsletters and promotional emails.',
  create_account: 'Create Account',
  taxes: `To avoid sales tax, you must submit a sales tax exemption certificate to
    <strong style="font-family:'Acrom-Medium'">CustomerService@qwikkit.com.</strong> You must
    receive a confirmation email, before an order is finalized. You are welcome to enter, price
    and save orders while your tax exemption certificate is being processed.
    <br>
    <br>
    Highly qualified customers may qualify for sales on open account. Download
    and submit a credit application, and allow 3 weeks for credit approval.
    <br>
    <a
    href='https://cinchwebapp.blob.core.windows.net/docs/Cinch%20-%20Credit%20Application%202017-01-13.pdf'
    download
     style="
       color: #7EBD49;
       text-decoration: none;
       border-bottom: 1px solid #7EBD49;
       padding-bottom: 2px;
       cursor: pointer;" target="_blank">
       Download Credit Application Here
     </a>`,
     po: 'Does this account require a purchase order or WTN number?',
     require_different_invoices: 'Send a separate invoice for every unit',
     credit_limit: 'What is your credit limit.',
     salesperson_code: 'Please select the Salesperson Code. ',
     service_zone_code: 'Please select the delivery pricing group. Use 1 as the default, unless there are special circumstances.',
     // tslint:disable-next-line:max-line-length
     tax_bus_posting_group: 'Please select the sales tax category. Sales taxes are assigned according to the ship-to destination of the customer. If a customer ships to multiple states, they may require different accounts.',
     // tslint:disable-next-line:max-line-length
     customer_pricing_group: 'Please select the customer pricing group. Use C10 for contractors and M10 for property managers unless you have written authorization from management.',
     fedex_acount: 'Ship collect on customer’s FedEx account.',
     password_info: 'Password (Must contain a capital letter, a small letter, and a number or special character)',
     special_instructions: 'Enter any special invoicing instructions. For example, does the customer use ops technology?',
     special_reference: 'Enter any special invoicing reference number to be included on customer’s invoices.',
     create_user: 'Create User',
     update_user: 'Update User',
     delete_user: 'Delete User',
     add_account: 'Add Account',
     credentialing_service: 'Credentialing Service',
     invoicing_portal: 'Invoicing Portal'
  };

export const accountTxt2: AccountTxt = Object.assign({save_account: 'Save Information'}, {edit_account: 'Edit Information'}, {
  account_creation: 'Account Information',
  customer_details: 'Customer Details',
  company_name: 'Company Name',
  login_information: 'Login information.',
  office_info: 'Office Contact Information',
  // tslint:disable-next-line:max-line-length
  invoicing_emails: 'Please enter email for invoicing. You may enter multiple emails, separated by semicolons, up to a total of 80 characters.',
  user_role: 'Role.',
  apt_renovate: 'How many apartments does the customer renovate each year.',
  about_us: 'How did the customer hear about Qwikkit?',
  spam: 'Yes, I would like to receive Qwikkit newsletters and promotional emails.',
  create_account: 'Edit',
  taxes: `To avoid sales tax, you must submit a sales tax exemption certificate to
    <strong style="font-family:'Acrom-Medium'">CustomerService@Qwikkit.com.</strong> You must
    receive a confirmation email, before an order is finalized. You are welcome to enter, price
    and save orders while your tax exemption certificate is being processed.
    <br>
    <br>
    Highly qualified customers may qualify for sales on open account. Download
    and submit a credit application, and allow 3 weeks for credit approval.
    <br>
    <a
    href='https://cinchwebapp.blob.core.windows.net/docs/Cinch%20-%20Credit%20Application%202017-01-13.pdf'
    download
    style="
      color: #7EBD49;
      text-decoration: none;
      border-bottom: 1px solid #7EBD49;
      padding-bottom: 2px;
      cursor: pointer;" target="_blank">
      Download Credit Application Here
    </a>`,
    po: 'Does this account require a purchase order or WTN number?',
    require_different_invoices: 'Send a separate invoice for every unit',
    credit_limit: 'Credit Limit.',
    salesperson_code: 'Salesperson Code.',
    service_zone_code: 'Service Zone Code.',
    tax_bus_posting_group: 'Tax Bus Posting Group.',
    customer_pricing_group: 'Customer Pricing Group.',
    fedex_acount: 'Ship collect on customer’s FedEx account.',
    password_info: 'Password (Must contain a capital letter, a small letter, and a number or special character)',
    special_instructions: 'Enter any special invoicing instructions. For example, does the customer use ops technology?',
    special_reference: 'Enter any special invoicing reference number to be included on customer’s invoices.',
    create_user: 'Create User',
    update_user: 'Update User',
    delete_user: 'Delete User',
    add_account: 'Add Account',
    credentialing_service: 'Credentialing Service',
    invoicing_portal: 'Invoicing Portal'
});

export const userCreditTxt: UserCreditTxt = {
  title: 'Your credit has been approved!',
  credit_line: 'Credit Line',
  orders_process: 'Open Orders',
  account_receivable: 'Account Balance',
  cart_orders: 'Orders in Cart',
  available_credit: 'Available Credit',
  last_invoice: 'Last Invoice'
};

export const resetPasswordPlaceholder: ResetPassPlaceholder = {
  title: 'RESET YOUR PASSWORD',
  set_password: 'SET YOUR NEW PASSWORD',
  new_password: 'New password*',
  repeat_password: 'Repeat password*',
  change: 'Change'
};

export const resetPasswordPlaceholder2: ResetPassPlaceholder = {
  title: 'CHANGE YOUR PASSWORD',
  set_password: 'SET YOUR NEW PASSWORD',
  new_password: 'New password*',
  repeat_password: 'Repeat password*',
  change: 'Change',
  current_password: 'Current password*'
};

export const inputError: InputError = {
  required: '*This field is required.',
  invalid_password: '*Password too weak. (Min: 8 characters, lowercase, uppercase, numbers and special characters).',
  password_not_equal: `*The passwords doesn't match.`,
  password_equal: '*The new password cannot be the same as the previous.'
};


