export enum Status {
  Received = 1,
  Processing = 2,
  Completed = 3,
  Error = 4
};

export interface IntegrationNavAccountStatusMessage {
  userEmail: string;
  navAccountNumber: string;
  currentStatus: Status;
  messageDetails: string;
  dateCreated: Date;
}
