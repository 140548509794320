import { States } from './aparment-complex-info';
import { SuggestedAddresses } from './location';
export class Fedex {
  constructor(
    public streetLines: Array<string>,
    public postalCode: number | string,
    public city: string,
    public stateOrProvince: string,
    public countryCode: string,
    public warningMessage?: string
  ) {}
}

export interface ModalFedexData {
  addressToValidate: Fedex;
  stateList: States[];
  addressSelection?: AddressSelection;
  addressSuggestion?: AddressSelection;
  modalState?: string;
  addressSugestions?: SuggestedAddresses[];
}

export interface AddressSelection {
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressStateID: number;
  addressStateName: string;
  addressZipCode: string;
}


