import { ShippingAddressOptions, ShippingAddressForm, PalletizeContact } from '../types/shipping-address';

export const ShippingAddressValues: ShippingAddressOptions = {
  title: 'Where do you want the products shipped?',
  billingAddress: 'Customer Billing Address',
  projectAddress: 'Location Address',
  leasingOfficeAddress: 'Leasing Office',
  maintenanceOfficeAddress: 'Maintenance Office',
  newAddress: 'Enter a new address',
  customerPickUp: 'Customer Pick Up',
  toContinue: '* Fill all the required fields to continue.',
  invalidPhone: '* Enter a valid phone.',
  cpuEmail: '* Enter a valid Email',
  pOBox: '* Shipping is not allowed to PO Boxes.',
  selectedOption: false
};
export const ShippingAddressPlaceholder: ShippingAddressForm = {
  shippingAddressName: 'Ship To',
  shippingAddressLine1: 'Address Line 1',
  shippingAddressLine2: 'Address Line 2',
  shippingAddressLine3: 'Address Line 3',
  shippingAddressZipCode: 'Zip Code',
  shippingAddressCity: 'City',
  shippingAddressState: 'State',
  shippingAddressPhone: 'Phone Number',
  shippingAddressNotes: 'Special Shipping Instruction or Notes',
  entranceCode: 'Entrance Code'
};

export const PalletizeContactPlaceholder: PalletizeContact = {
  id: 0,
  fullName: 'Who should we contact:',
  phoneNumber: 'Contact Phone:',
  emailAddres: 'Contact Email:',
  contactTypeId: 1,
};
