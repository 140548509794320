export interface ReasonCode {
    idReasonCode: number;
    reasonCode: string;
    description: string;
    isActive: boolean;
    idCategory: number;
    category: string;
    isFreeFormInput: boolean;
    isNoCharge: boolean;
}

export interface ResonCodeGroup {
    category: string;
    reasonCodes: ReasonCode[]
}

export enum ReasonGroupEnum{    
    NoChargeAndPartsOrder = "No charge and parts orders"
}