import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalFunctionsService {
  public possiblePOBoxBeginnings: string[] = ['p.o', 'p.o.', 'p.o box', 'p.o. box', 'po box', 'po. box'];

  constructor() { }

  checkIsPOBox(addressLine: string) {
    let isPoBox = false;
    for (let start of this.possiblePOBoxBeginnings) {

      isPoBox = addressLine.toLowerCase().trim().startsWith(start);

      if (isPoBox) break;
    }
    return isPoBox;
  }
}
